import React, { useState } from "react";
import { Spinner } from "source/components/library/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useCreateReport } from "source/hooks/matrix/useCreateReport";
import { useGlobalNavigator } from "source/hooks/useSetRouter";
import { setMatrixChatDocumentListId } from "source/redux/matrixChat";
import { getCurrentOrg } from "source/redux/organization";
import logger from "source/utils/logger";
import { Tooltip } from "source/components/shared/Tooltip";
import { PUBLICS_DOCUMENT_LIST_ID } from "source/constants/documentList";
import { LoadingIconButton, MaterialSymbolsIcon } from "core";

export const NewMatrixButton = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(getCurrentOrg)?.id;
  const { goToMatrix } = useGlobalNavigator();
  const { createReport } = useCreateReport();

  const onCreateReport = async () => {
    if (!orgId) {
      logger.error("Trying to create matrix with no orgId");
      return;
    }
    const report = await createReport({
      orgId,
      replaceReport: true,
    });
    goToMatrix(report.id, report.tabs[0]?.tab_id);
    // Empty matrix so we default to the publics document list
    // Note this only works on the homepage - low priority issue to fix
    // when the button is clicked while a matrix is open. Problem is that
    // matrix state is getting reset to default values upon creation
    // and this is not being overwritten when the matrix is reopened.
    dispatch(setMatrixChatDocumentListId(PUBLICS_DOCUMENT_LIST_ID));
  };

  return (
    <div className="flex h-[44px] items-center justify-between">
      <div className={"text-lg font-medium text-neutral-800"}>Matrix</div>
      <Tooltip title="Create a new matrix">
        <LoadingIconButton variant="text" size={"lg"} onClick={onCreateReport}>
          <MaterialSymbolsIcon
            icon="edit_square"
            size="lg"
            variant="outlined"
          />
        </LoadingIconButton>
      </Tooltip>
    </div>
  );
};
