import { queryClient } from "pages/_app";
import { docsKeys } from "source/api/docs/docsKeyFactory";
import { SimpleDocsBatchResponse } from "source/api/docs/types";
import { AppThunk } from "source/redux";
import {
  initializeDocumentList,
  setDefaultOpenUploadState,
  setShouldRunDocsQuery,
} from "source/redux/addDocs";
import { getActiveReportId } from "source/redux/matrix";
import { clearToasts } from "source/redux/ui";

export const openAddDocsModal =
  (openModal: (props: any) => void): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const reportId = getActiveReportId(state);

    const initialDocsData = queryClient.getQueryData<SimpleDocsBatchResponse>(
      docsKeys.batchDocs(reportId)
    );

    // Pre-set the document list with the initial documents if we have them
    dispatch(initializeDocumentList(initialDocsData?.docs ?? []));
    dispatch(setDefaultOpenUploadState(false));
    dispatch(setShouldRunDocsQuery(true));
    dispatch(clearToasts());

    openModal({});
  };
