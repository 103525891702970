import React, { useEffect } from "react";
import { NextPage } from "next";
import dynamic from "next/dynamic";
import { useGetRouter } from "source/hooks/useGetRouter";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { AppLayout } from "source/components/shared/AppLayout/AppLayout";
import { Sidebar } from "source/components/matrix/home/sidebar/Sidebar";
import { getUserToggledSidebar, setSidebarVisible } from "source/redux/sidebar";
import { useAppDispatch } from "source/redux";
import { useFeatureFlag } from "source/hooks/common/useFeatureFlag";
import { MatrixPageTitle } from "source/components/matrix/MatrixPageTitle";
import { DocumentListUploadContextProvider } from "source/components/document-list/DocumentListUploadContext";
import { DocumentListGridContextProvider } from "source/components/document-list/DocumentListGridContext";
import { openAddDocsModal } from "source/thunks/matrix/addDocs";
import { ModalTypes } from "source/components/modals/modals.types";
import { useModal } from "source/components/modals/useModal";

// Use dynamic imports to reduce bundle size
const MatrixHome = dynamic(
  () => import("source/components/matrix/home/main/MatrixHome"),
  { ssr: false }
);
const MatrixTopbar = dynamic(
  () => import("source/components/matrix/matrix-top-bar/MatrixTopbar"),
  { ssr: false }
);
const MatrixEditor = dynamic(
  () => import("source/components/matrix/MatrixEditor"),
  { ssr: false }
);
const ReportsGridContextProvider = dynamic(
  () => import("source/components/matrix/contexts/ReportsGridContext"),
  { ssr: false }
);
const ReportsWebsocketProvider = dynamic(
  () => import("source/components/matrix/contexts/ReportsWebsocketContext"),
  { ssr: false }
);

const MatrixPage: NextPage = () => {
  const { router } = useGetRouter();
  const activeReportId = router.query.matrix_id;

  const enableSidebar = useFeatureFlag("enableMatrixSidebar");

  const dispatch = useAppDispatch();
  const hasUserToggledSidebar = useSelector(getUserToggledSidebar);

  const addDocsModal = useModal(ModalTypes.AddDocumentsModal);
  const defaultSource = router.query.source;

  // Open/close the sidebar when going between matrix home or a matrix
  useEffect(() => {
    // Don't auto-change the sidebar state once the user has toggled it
    if (hasUserToggledSidebar) {
      return;
    }

    if (!activeReportId) {
      dispatch(setSidebarVisible(true));
    } else {
      dispatch(setSidebarVisible(false));
    }
  }, [activeReportId, hasUserToggledSidebar, dispatch]);

  useEffect(() => {
    if (defaultSource) {
      dispatch(openAddDocsModal(addDocsModal.open));
    }
  }, [addDocsModal.open, defaultSource, dispatch]);

  if (activeReportId) {
    return (
      // TODO: Wrap AppLayout with these by default
      <DocumentListGridContextProvider>
        <DocumentListUploadContextProvider>
          <ReportsGridContextProvider>
            <ReportsWebsocketProvider>
              <AppLayout
                SidebarComponent={
                  !activeReportId || !!enableSidebar ? (
                    <Sidebar variant={activeReportId ? "matrix" : "home"} />
                  ) : null
                }
              >
                <MatrixPageTitle />
                <MatrixTopbar />
                <MatrixEditor reportId={activeReportId} />
              </AppLayout>
            </ReportsWebsocketProvider>
          </ReportsGridContextProvider>
        </DocumentListUploadContextProvider>
      </DocumentListGridContextProvider>
    );
  }

  return (
    <DocumentListGridContextProvider>
      <DocumentListUploadContextProvider>
        <AppLayout
          SidebarComponent={
            !activeReportId || !!enableSidebar ? (
              <Sidebar variant={activeReportId ? "matrix" : "home"} />
            ) : null
          }
        >
          <MatrixPageTitle />
          <MatrixHome />
        </AppLayout>
      </DocumentListUploadContextProvider>
    </DocumentListGridContextProvider>
  );
};

// fast/cached SSR page
// use this for public pages so we can cache ssr
export default (props) => {
  const { asPath } = useRouter();
  return withPageAuthRequired(MatrixPage, { returnTo: asPath })(props);
};
