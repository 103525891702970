import { ReportInfo } from "source/components/matrix/types/reports.types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { highlightSubstringMatch } from "../helpers";
import { classNames, MaterialSymbolsIcon } from "core";
import { Tooltip } from "source/components/shared/Tooltip";
import { MatrixSidebarContextMenu } from "./MatrixSidebarContextMenu";
import Link from "next/link";
import {
  reportsKeys,
  useRenameReportMutation,
  useSetMatrixBookmarkedMutation,
} from "source/api/matrix/useQueryReports";
import { useModal } from "source/components/modals/useModal";
import { ModalTypes } from "source/components/modals/modals.types";
import { THIS_MATRIX_ID } from "source/constants/documentList";
import { setMatrixChatDocumentListId } from "source/redux/matrixChat";
import logger from "source/utils/logger";
import { queryClient } from "pages/_app";

type MatrixSidebarRowWrapperProps = {
  active?: boolean;
  children: React.ReactNode;
  styleOverrides?: string;
  onClick?: () => void;
};

type MatrixSidebarRowProps = {
  query?: string;
  active?: boolean;
  isOpen?: boolean;
  isUnread?: boolean;
  reportInfo: ReportInfo;
  styleOverrides?: string;
  onClick?: () => void;
  setActiveMatrixSidebarRowId: (id: string | null) => void;
};

export const MatrixSidebarRowWrapper = ({
  active,
  children,
  styleOverrides,
  onClick,
}: MatrixSidebarRowWrapperProps) => (
  <div
    onClick={onClick}
    className={classNames(
      "group flex cursor-pointer items-center gap-1.5 rounded-[4px] px-2 py-1 leading-6 transition-all hover:bg-gray-200 active:bg-opacity-60",
      active && "bg-gray-200",
      styleOverrides
    )}
  >
    {children}
  </div>
);

export const MatrixSidebarRow = ({
  query,
  active,
  isOpen,
  isUnread,
  reportInfo,
  styleOverrides,
  onClick,
  setActiveMatrixSidebarRowId,
}: MatrixSidebarRowProps) => {
  const dispatch = useDispatch();
  const { name, id, is_owner, bookmarked } = reportInfo;

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [renameValue, setRenameValue] = useState(name);

  const deleteMatrixModal = useModal(ModalTypes.DeleteMatrixModal);

  const { mutate: bookmarkReport } = useSetMatrixBookmarkedMutation();

  const { isLoading: isTitleRenaming, mutateAsync: renameTitle } =
    useRenameReportMutation({
      onError: (err: any, { reportId, oldReportName }) => {
        logger.error(`Error renaming report with id=${reportId}: `, err);
        setRenameValue(oldReportName);
      },
      onSuccess: (_, { reportId }) => {
        queryClient.setQueryData(
          reportsKeys.userReports(),
          (prev?: ReportInfo[]) => {
            if (!prev) return prev;

            const updatedReports = prev.map((report) => {
              if (report.id === reportId) {
                return {
                  ...report,
                  name: renameValue,
                };
              }

              return report;
            });

            return updatedReports;
          }
        );
      },
    });

  useEffect(() => {
    if (isRenaming && inputRef) {
      inputRef.focus();
    }
  }, [inputRef, isRenaming]);

  return (
    <MatrixSidebarRowWrapper
      styleOverrides={classNames(isOpen && "bg-gray-200", styleOverrides)}
      onClick={onClick}
    >
      {isRenaming ? (
        <React.Fragment>
          <div className="flex flex-grow flex-row items-center gap-2 py-0.5 pl-2">
            <MaterialSymbolsIcon
              filled
              size="base"
              variant="rounded"
              icon="auto_awesome_mosaic"
              className="leading-none !text-gray-500"
            />
            <input
              ref={setInputRef}
              value={renameValue}
              maxLength={500} // lol
              className="flex-grow rounded border border-transparent px-0.5 text-xs font-medium leading-[22px] text-gray-800 hover:border-gray-300 focus:border-hebbiaBlue focus:outline-none"
              onChange={(e) => setRenameValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  inputRef?.blur();
                }
              }}
              onBlur={async () => {
                if (isTitleRenaming) return;

                if (!renameValue.trim().length) {
                  setRenameValue(name);
                  setIsRenaming(false);
                  return;
                }

                await renameTitle({
                  reportId: id,
                  oldReportName: name,
                  newReportName: renameValue,
                });
                setIsRenaming(false);
              }}
            />
          </div>
          <div
            className={classNames(
              "mr-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-[4px] p-1 transition-all hover:bg-gray-300 group-hover:opacity-100",
              active ? "opacity-100" : "opacity-0"
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <span className="material-symbols-rounded text-base text-gray-500">
              check
            </span>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Link
            href={`/matrix?matrix_id=${id}`}
            aria-disabled={isOpen}
            onClick={(e) => {
              // Default to the THIS_MATRIX_ID document list for created matrices
              dispatch(setMatrixChatDocumentListId(THIS_MATRIX_ID));
              if (isOpen) {
                e.preventDefault();
              }
            }}
            className={classNames(
              "flex flex-1 items-center gap-2 py-0.5 pl-2",
              isOpen && "pointer-events-none" // Disable clicking if the matrix is already open
            )}
          >
            <Tooltip title={name}>
              <div className="flex flex-row items-center gap-2">
                <MaterialSymbolsIcon
                  filled
                  size="base"
                  variant="rounded"
                  icon="auto_awesome_mosaic"
                  className="leading-none !text-gray-500"
                />
                <span
                  className="line-clamp-1 text-ellipsis break-all text-xs font-medium leading-6 text-gray-800"
                  dangerouslySetInnerHTML={{
                    __html: query ? highlightSubstringMatch(name, query) : name,
                  }}
                />
              </div>
            </Tooltip>
            {!is_owner && (
              <MaterialSymbolsIcon
                filled
                icon="group"
                size="sm"
                variant="sharp"
                style={{
                  fontVariationSettings: `'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24`,
                }}
                className="leading-6 text-gray-500"
              />
            )}
            {isUnread && (
              <span className="flex h-full w-2">
                <span className="relative mb-[1px] inline-flex h-1.5 w-1.5 rounded-full bg-hebbiaBlue" />
              </span>
            )}
          </Link>
          <div
            className={classNames(
              "mr-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-[4px] p-1 transition-all hover:bg-gray-300 group-hover:opacity-100",
              active ? "opacity-100" : "opacity-0"
            )}
          >
            <MatrixSidebarContextMenu
              handleRenameMatrix={() => {
                setIsRenaming(true);
                setRenameValue(name);
              }}
              handleBookmarkReport={() => {
                bookmarkReport({
                  reportId: id,
                  bookmarked: !bookmarked,
                });
              }}
              handleMatrixDelete={() => {
                deleteMatrixModal.open({
                  matrixId: id,
                });
              }}
              handleContextMenuOpen={() => {
                setActiveMatrixSidebarRowId(id);
              }}
              handleContextMenuClose={() => {
                setActiveMatrixSidebarRowId(null);
              }}
              bookmarked={bookmarked}
              isOwner={is_owner}
            />
          </div>
        </React.Fragment>
      )}
    </MatrixSidebarRowWrapper>
  );
};
